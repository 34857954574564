import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";

export const useRedirectBack = () => {
  const location = useLocation();
  const history = useHistory();
  const { goToCommunityRootPath } = useLinkToRoot();

  const [currentLocation] = useState(location);
  const { from: prevLocation } = currentLocation?.state ?? {};

  const redirectBack = useCallback(
    (post_login_redirect = null) => {
      if (prevLocation?.pathname) {
        const { pathname, search } = prevLocation;
        history.push({ pathname, search });
      } else if (prevLocation?.path) {
        const { path, search } = prevLocation;
        history.push({ pathname: path, search });
      } else {
        goToCommunityRootPath(post_login_redirect);
      }
    },
    [prevLocation, history, goToCommunityRootPath],
  );

  return {
    redirectBack,
  };
};
